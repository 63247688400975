@import '../../../styles/_colors';

.Navbar {
  .navbar-default {
    background-color: $dark-blue;
    border-color: $light-blue;
  }

  .navbar-default .navbar-brand {
    color: $white;
  }

  .navbar-default .navbar-brand:hover,
  .navbar-default .navbar-brand:focus {
    color: $yellow;
  }

  .navbar-default .navbar-text {
    color: $white;
  }

  .navbar-default .navbar-nav > li > a {
    color: $white;
  }

  .navbar-default .navbar-nav > li > a:hover,
  .navbar-default .navbar-nav > li > a:focus {
    color: $yellow;
  }

  .navbar-default .navbar-nav > .active > a,
  .navbar-default .navbar-nav > .active > a:hover,
  .navbar-default .navbar-nav > .active > a:focus {
    color: $yellow;
    background-color: $light-blue;
  }

  .navbar-default .navbar-nav > .open > a,
  .navbar-default .navbar-nav > .open > a:hover,
  .navbar-default .navbar-nav > .open > a:focus {
    color: $yellow;
    background-color: $light-blue;
  }

  .navbar-default .navbar-toggle {
    border-color: $light-blue;
  }

  .navbar-default .navbar-toggle:hover,
  .navbar-default .navbar-toggle:focus {
    background-color: $light-blue;
  }

  .navbar-default .navbar-toggle .icon-bar {
    background-color: $white;
  }

  .navbar-default .navbar-collapse,
  .navbar-default .navbar-form {
    border-color: $white;
  }

  .navbar-default .navbar-link {
    color: $white;
  }

  .navbar-default .navbar-link:hover {
    color: $yellow;
  }

  @media (max-width: 767px) {
    .navbar-default .navbar-nav .open .dropdown-menu > li > a {
      color: $white;
    }
    .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover,
    .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus {
      color: $yellow;
    }
    .navbar-default .navbar-nav .open .dropdown-menu > .active > a,
    .navbar-default .navbar-nav .open .dropdown-menu > .active > a:hover,
    .navbar-default .navbar-nav .open .dropdown-menu > .active > a:focus {
      color: $yellow;
      background-color: $light-blue;
    }
  }
}