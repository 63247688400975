@import '~@fortawesome/fontawesome-pro/css/all.css';
@import './_colors';

body {
  background-color: $background !important;
  // padding-top: 70px;
}

body h1,
body h2,
body h3,
body h4,
body h5,
body h6 {
  text-align: center;
}

.main-container {
  text-align: center;
}

/* default button */

.btn-primary {
  background-color: $default !important;
  color: $white !important;
  border-color: $border-one !important;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
  background-color: $default-active !important;
  color: $white !important;
  border-color: $border-one !important;
}

.panel-default {
  margin-bottom: 10px;
}

/*SIDENAVBAR*/

@media (max-width: 768px) {
  .navbar-fixed-side {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media (min-width: 768px) {
  .navbar-fixed-side {
    position: fixed;
    margin: 0 -15px;
    height: 100vh;
    width: inherit;
    overflow: auto;
    border-top-width: 0;
    border-bottom-width: 0;
    border-radius: 0;
  }
  .navbar-fixed-side .container,
  .navbar-fixed-side .container-fluid {
    width: auto;
    padding-left: 0;
    padding-right: 0;
  }
  .navbar-fixed-side .navbar-header {
    float: none;
  }
  .navbar-fixed-side .navbar-brand {
    height: auto;
  }
  .navbar-fixed-side > .container .navbar-brand,
  .navbar-fixed-side > .container-fluid .navbar-brand {
    margin-left: 0;
  }
  .navbar-fixed-side .navbar-collapse {
    width: 100%;
    border-top: 1px solid $navbar-eggshell1;
  }
  .navbar-fixed-side .navbar-nav {
    float: none;
    margin: 0 -15px;
  }
  .navbar-fixed-side .navbar-nav > li {
    float: none;
  }
  .navbar-fixed-side .navbar-nav > li > a {
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid $navbar-eggshell1;
  }
  .navbar-fixed-side .navbar-form {
    margin: 0;
    margin-left: -15px;
    margin-right: -15px;
    padding: 10px 15px;
    border-bottom: 1px solid $navbar-eggshell1;
  }
  .navbar-fixed-side .navbar-text {
    float: none;
    margin-left: 0;
    margin-right: 0;
  }
  .navbar-fixed-side .navbar-left,
  .navbar-fixed-side .navbar-right {
    float: none !important;
  }
  .navbar-fixed-side .navbar-nav .dropdown-menu {
    position: static;
    float: none;
    width: auto;
    margin-top: 0;
    background-color: transparent;
    border: 0;
    box-shadow: none;
    border-bottom: 1px solid $navbar-eggshell1;
  }
  .navbar-fixed-side .navbar-nav .dropdown-menu > li > a,
  .navbar-fixed-side .navbar-nav .dropdown-menu .dropdown-header {
    padding: 5px 15px 5px 25px;
  }
  .navbar-fixed-side .navbar-nav .dropdown-menu > li > a {
    line-height: 20px;
    color: $navbar-lightest-grey;
  }
  .navbar-fixed-side .navbar-nav .dropdown-menu > li > a:hover,
  .navbar-fixed-side .navbar-nav .dropdown-menu > li > a:focus {
    background-image: none;
  }
  .navbar-fixed-side .navbar-nav .dropdown-menu > .active > a {
    background-color: $navbar-eggshell1;
    color: $grey2;
  }
  .navbar-fixed-side .navbar-nav > li > a:hover,
  .navbar-fixed-side .navbar-nav > li > a:focus,
  .navbar-fixed-side .navbar-nav .dropdown-menu > li > a:hover,
  .navbar-fixed-side .navbar-nav .dropdown-menu > li > a:focus {
    background-color: $navbar-eggshell2;
    color: $grey1;
  }
  .navbar-fixed-side .dropdown > .dropdown-toggle,
  .navbar-fixed-side .dropdown-menu > .dropdown-header {
    background-color: transparent !important;
    color: $navbar-light-grey !important;
    cursor: default;
    font-size: 0.8em;
    text-transform: uppercase;
    border-bottom: none;
    padding-bottom: 0;
  }
  .navbar-fixed-side .dropdown-toggle .caret {
    display: none;
  }
  .navbar-fixed-side .dropdown-menu {
    display: block;
  }
  .navbar-fixed-side.navbar-inverse .navbar-collapse,
  .navbar-fixed-side.navbar-inverse .navbar-nav > li > a,
  .navbar-fixed-side.navbar-inverse .navbar-form,
  .navbar-fixed-side.navbar-inverse .navbar-nav .dropdown-menu {
    border-color: $navbar-medium-grey;
  }
  .navbar-fixed-side.navbar-inverse .divider {
    background-color: $navbar-medium-grey;
  }
  .navbar-fixed-side.navbar-inverse .navbar-nav .dropdown-menu > li > a {
    color: $navbar-light-grey;
  }
  .navbar-fixed-side.navbar-inverse .navbar-nav .dropdown-menu > .active > a {
    background-color: $black;
    color:  $white;
  }
  .navbar-fixed-side.navbar-inverse .navbar-nav > li:not(.active) > a:hover,
  .navbar-fixed-side.navbar-inverse .navbar-nav > li:not(.active) > a:focus,
  .navbar-fixed-side.navbar-inverse
    .navbar-nav
    .dropdown-menu
    > li:not(.active)
    > a:hover,
  .navbar-fixed-side.navbar-inverse
    .navbar-nav
    .dropdown-menu
    > li:not(.active)
    > a:focus {
    background-color: $navbar-dark-grey;
    color:  $white;
  }
  .navbar-fixed-side.navbar-inverse .dropdown > .dropdown-toggle {
    color: $navbar-lightest-grey !important;
  }
}

.day {
  border: 2px solid $black;
}

.nextWeek {
  margin-top: 10px;
}

.crap {
  position: relative !important;
}


/* don't show week numbers in calendar */
.cal-week-box-cell {
  display: none;
}

.bold-text {
  font-weight: bold;
}

#calendar-dashboard a {
  color: $blue;
  text-decoration: underline;
}

mwl-calendar .cal-year-box .row {
  border-color: $black;
}

mwl-calendar .cal-month-box .cal-row-fluid {
  border-color: $black;
}

.cal-cell {
  border: solid $black 1px;
}

.github-link {
  color: $black;
}

#submit-dashboard .panel-heading {
  line-height: 35px;
}

.github-url-box {
  padding-top: 5px;
  border: 2px dotted $border-one;
  height: 34px;
  overflow-x: scroll;
  white-space: nowrap;
}

.github-url-input {
  height: 34px;
}

.no-pad {
  padding: 0px;
}