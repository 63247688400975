@import '../../../styles/colors';

.BadStudent {
  .treehouse-img {
    height: 28px;
  }

  .logo {
    margin: 0 10px;
  }

  .website-link {
    color: $purple;
  }

  .treehouse-check {
    color: $dark-green;
    background-color: $white;
  }

  .danger-points {
    background-color: $red;
    color: $white;
  }

  .middle-points {
    background-color: $yellow;
  }

  .almost-points {
    background-color: $light-green;
  }
}