@import '../../../styles/colors';

.GoodStudent {
  margin: 15px 0;
  .card {
    border-width: 3px !important;
  }
  .treehouse-img {
    height: 28px;
  }

  .website-link {
    color: $purple;
  }

  a {
    color: $dark-blue;
  }

  .github-link {
    color: $black !important;
  }
}