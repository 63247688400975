@import '../../../styles/_colors';

.AssignmentListGroup {
  margin-bottom: 40px;

  h3 {
    color: $light-blue;
  }

  a {
    color: $bootstrap-primary;
  }
}