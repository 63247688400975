@import '../../../styles/_colors';

.ClassCalendar {
  text-align: center;
  .cal-holder {
    margin-top: 25px;
    background-color: $white;
    padding: 15px;
    height: 55vh;
  }
  .rbc-toolbar-label {
    font-size: 30px;
  }
  .bg-purple{
    background-color: $cal-purple !important;
  }

  .border-purple {
      border-color: $cal-purple !important;
  }

  .event{
    a {
      color: $bootstrap-primary;
    }
  }

  .recordings {
    & > *{
      display: block;
    }
    & a {
      margin-top: 15px;
      margin-bottom: 15px;
    }
  }
}