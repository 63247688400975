$black: black;
$blue: blue;
$dark-blue: #01579b;
$dark-green: darkgreen;
$light-blue: #1b71b5;
$light-green: rgb(53, 225, 53);
$purple: purple;
$red: red;
$white: #fff;
$yellow: #ffc107;

// bootstrap colors
$bootstrap-primary:       #007bff;
$bootstrap-secondary:     #6c757d;
$bootstrap-success:       #28a745;
$bootstrap-info:          #17a2b8;
$bootstrap-warning:       #ffc107;
$bootstrap-danger:        #dc3545;
$bootstrap-light:         #f8f9fa;
$bootstrap-dark:          #343a40;

// Body Colors
$background: #e1f5fe;
$border-one: #adadad;
$border-two: #ccc;
$navbar-lightest-grey: #777777;
$navbar-light-grey: #9d9d9d;
$navbar-dark-grey: #2f2f2f;
$navbar-medium-grey: #363636;
$navbar-eggshell1:#e7e7e7;
$navbar-eggshell2:#f0f0f0;
$grey1: #333;
$grey2: #555;


// button colors
$default: #ffc107;
$default-active: #e6a800;

$royal: #91669c;
$royal-light: #d6c5db;

// Calendar
$cal-purple: #6f42c1;


// Submit Board
$submit-white: #EEEEEE;
$submit-pink: pink;
$submit-green: green;
$submit-orange: orange;
$submit-blue: skyblue;
$submit-yellow: yellow;

